<template>
  <div role="group"
       class="input-group mb-3 w-100">
    <!-- <div class="input-group-prepend">
      <div class="input-group-text">Choose Date</div>
    </div> -->
    <input type="text"
           class="form-control"
           ref="dateRangePickerInput" style="background-color: white !important;"/>
    <b-button v-if="isDateSelected" @click="clearDate">
      <b-icon icon="trash"></b-icon>
    </b-button>
  </div>
</template>
<script>
import 'flatpickr/dist/flatpickr.css';
import flatpickr from 'flatpickr';

export default {
  name: "DateRangePicker",
  data: () => ({
    date: "",
    vendorList: [],
    loading: false,
    searchInput: "",
    filteredList: [],
    documentMeta: [],
    flatpickrInstance: null,
    isDateSelected: false
  }),
  props: {
    defaultDate: Array
  },
  computed: {},
  mounted() {
    this.flatpickrInstance = flatpickr(this.$refs.dateRangePickerInput, {
      defaultDate: this.defaultDate,
      maxDate: 'today',
      mode: 'range',
      dateFormat: 'Y-m-d',
      onClose: (dates) => {
        const startDate = dates.length > 0 ? this.getDateFormattedString(dates[0]) : null;
        const endDate = dates.length == 2 ? this.getDateFormattedString(dates[1]) : startDate;
        this.isDateSelected = dates.length > 0;

        this.$emit('onDateSelected', { startDate: startDate, endDate: endDate });
      },
    });
  },
  methods: {
    getDateFormattedString(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    clearDate() {
      if (this.flatpickrInstance) {
        this.flatpickrInstance.clear();
        this.isDateSelected = false;
      }
    }
  }
}
</script>